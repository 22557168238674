@use "sass:math";
// @import "src/assets/scss/variables";

.message {
  position: fixed;
  top: 45%;
  left: 0;
  z-index: 1001;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5715;
  color: #fff;
  list-style: none;
  pointer-events: none;
}

.message-notice {
  max-width: 100%;
  padding: 8px;
  margin: 0 auto;
  text-align: center;
  animation-duration: 300ms;
  animation-fill-mode: both;

  &.enter {
    animation-name: move-up-in;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }

  &.leave {
    animation-name: move-up-out;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
}

.message-notice-content {
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 10px 26px;
  text-align: left;
  word-break: break-all;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
}

@keyframes move-up-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transform-origin: 0 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
    transform-origin: 0 0;
  }
}

@keyframes move-up-out {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0 8px;
    opacity: 0;
  }
}
