@use "sass:math";
// @import "src/assets/scss/variables";

$header-height: 64px !default;
$color-primary: #d3af6e !default;
$color-white: #fff !default;
$color-gray: #666 !default;
$border-color-base: #717171 !default;
$border-color-button: #d3af6e !default;
$border-radius-base: 2px !default;
$background-color-base: #141414 !default;
$background-color-primary: #282828 !default;

/* 层级变量定义
*************/
$header-z-index: 6 !default;
$dialog-z-index: 7 !default;
$message-z-index: 8 !default;
$loading-z-index: 10000 !default;
//@import "src/assets/scss/variables";

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $loading-z-index;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width);
  margin: 0 auto;
  touch-action: none;
  background: rgba(0, 0, 0, 0.5);

  --animate-duration: 200ms;
  img {
    width: 60px;
    border-radius: 50%;
  }
}

.loading {
  position: relative;
  width: 44px;
  height: 44px;
  border: 2px solid #b9975e;
  border-top-color: transparent;
  border-radius: 100%;
  animation: circle infinite 0.75s linear;
  z-index: $loading-z-index + 1;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes line-scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}

.effectWrap {
  position: relative;
  width: 100px;
  height: 100px;
  .loadIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
