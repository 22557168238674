@use "sass:math";
@import "~@/assets/css/variables.scss";

$border-color-button: #d3af6e !default;

// @mixin border(
//   $color: $border-color-button,
//   $radius: $border-radius-base,
//   $usePosition: true
// ) {
//   border-radius: $radius;
//
//   @if $usePosition {
//     position: relative;
//   }
//
//   &::after {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 200%;
//     height: 200%;
//     content: "\20";
//     border: 1px solid $color;
//     border-radius: if(math.unit($radius) == px, $radius * 2, $radius);
//     transform: scale(0.5, 0.5);
//     transform-origin: top left;
//   }
// }

.button {
  border: none;
  outline: none;
  min-width: 130px;
  padding: 0 10px;
  white-space: nowrap;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  color: #DCCFB7;
  cursor: pointer;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) -12.5%, rgba(255, 255, 255, 0) 59.62%);
  box-shadow: 0 3px 5px rgba(32, 29, 24, 0.75),
  inset 0 0 5px 0 rgba(0, 0, 0, 0.1),
  inset 0 1px 0 1px rgba(224, 211, 188, 1),
  inset 0 -1px 0 1px rgba(186, 162, 119, 1),
  inset 0 2px 1px 1px rgba(0, 0, 0, 0.5),
  inset 1px 0 0 1px rgba(126, 105, 65, 1),
  inset -1px 0 0 1px rgba(126, 105, 65, 1);

  &:hover {
    color: #fbf9f6;
    background: $btn-primary-bg;
    // box-shadow: 0px 3px 5px rgba(32, 29, 24, 0.75), inset 0px 3px 2px rgba(0, 0, 0, 0.65);
  }

  &.default {
    color: #DCCFB7;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) -12.5%, rgba(255, 255, 255, 0) 59.62%);
  }

  &.primary {
    color: #fbf9f6;
    background: $btn-primary-bg;
  }

  &.disabled {
    opacity: 0.5;
    // color: #FBF9F6;
    // background: linear-gradient(180deg, #625B4E 14.58%, #353028 54.69%, #51483C 78.65%, #786952 100%);
  }

  &.highlight {
    color: #fff;
    background: linear-gradient(180deg, #CBB48A 14.58%, #4B4539 54.69%, #605647 78.65%, #95774C 100%);
  }

  &:not(.disabled):active {
    opacity: 0.8;
  }

  & + & {

  }
}
.header_btn {
  border: none;
  outline: none;
  min-width: 130px;
  padding: 0 10px;
  white-space: nowrap;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(180deg, rgba(12, 40, 125, 0.5) 29.37%, rgba(3, 10, 78, 0.5) 73.72%);
  box-shadow: 0 3px 5px rgba(32, 29, 24, 0.75),
  inset 0 0 5px 0 rgba(0, 0, 0, 0.1),
  inset 0 1px 0 1px #6383CD,
  inset 0 -1px 0 1px #193B74,
  inset 0 2px 1px 1px rgba(0, 0, 0, 0.5),
  inset 1px 0 0 1px #6383CD,
  inset -1px 0 0 1px #193B74;
  &:hover,
  &.primary {
    background: linear-gradient(180deg, #2043AC 29.37%, #0A1470 73.72%);
  }
  &:not(.disabled):active {
    opacity: 0.8;
  }
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  background: rgba(101, 90, 70, 0.7);

  &.small {
    position: absolute;
  }

  // &.luxury {
  //   top: 90px;

  // }

  --animate-duration: 200ms;
}

.dialog {
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-height: 94%;
  background: #313131;
  border-radius: 8px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
  0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.header {
  position: relative;
  flex: 0 0 auto;
  height: 70px;
  font-weight: 400;
  font-size: 20px;
  line-height: 70px;
  color: $theme-color;
  text-align: center;
  background: linear-gradient(180deg, #383735 0%, #242422 50%, #272625 100%);

  &__close {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: #383838 url("./icon_close.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
    border-radius: 4px;
  }

  &::after {
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    content: "\20";

    background: linear-gradient(90deg, rgba(229, 193, 128, 0.2) 0%, #E5C180 49.71%, rgba(229, 193, 128, 0.2) 100.47%);
  }
}

.content {
  flex: 1 1;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.message {
  padding-top: 34px;
  padding-bottom: 54px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.footer {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 30px 0;

  .button + button {
    margin-left: 30px;
  }
}
