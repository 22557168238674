@font-face {
  font-family: "D-DIN";
  font-style: normal;
  src: url("../fonts/D-DIN.ttf");
}

@font-face {
  font-family: "pingfang";
  font-style: normal;
  src: url("../fonts/PingFangSC.ttf");
  // src: url("../fonts/PingFangSC-Regular.ttf");
}

html {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.25);

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}

body {
  font-family: "pingfang";
  margin: 0;
}

ul, li {
  list-style: none;
}
