@import "~@/assets/css/variables.scss";

.mask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #717171;
  border-radius: 8px 8px 0 0;

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 22px;
    height: 66px;
    background: rgba(0, 0, 0, 0.5) url(~@/assets/images/star_vip_club.png) no-repeat center center;
    background-size: auto 40px;
    color: #D4BA8A;

    &:after,
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      content: "\20";
      background: linear-gradient(90deg, rgba(229, 193, 128, 0.2) 0%, #E5C180 49.71%, rgba(229, 193, 128, 0.2) 100.47%);
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    &.small {
      height: 50px;
      font-size: 18px;

      background-size: auto 30px;

      svg {
        width: 20px;
      }
    }
  }
}

.desk {
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;
  color: #FAF8F4;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;

  &__logo {
    width: 72px;
    height: auto;
    margin-left: 16px;
    margin-right: 40px;
  }

  &__right {
    flex: 1 1;
    display: flex;
    align-items: flex-end;
  }

  &__table,
  &__count {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__user,
  &__tableCode,
  &__circleWrap {
    display: inline-flex;
    align-items: center;
  }

  &__user {
    flex: 1 1;
    margin-left: 40px;
  }

  &__tableType,
  &__limitType {
    height: 29px;
    padding: 0 10px;
    line-height: 29px;
    text-align: center;
    border-radius: 100px;
    margin-bottom: 8px;
  }

  &__tableType {
    background: #717171;
    font-weight: bold;
    font-size: 16px;
  }

  &__limitType {
    margin-bottom: 6px;
    background: #2d2f2f;
  }

  &__banker,
  &__player,
  &__tie {
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    margin-right: 7px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;

  }

  &__banker {
    background: $bank-color;
  }

  &__player {
    background: $player-color;
  }

  &__tie {
    background: $tie-color;
  }

  &__countText {
    margin-right: 16px;
  }

  &__icon {
    width: 24px;
    height: auto;
    margin-right: 10px;
  }

  &.small {
    height: 66px;
    font-size: 12px;

    .desk__logo {
      width: 56px;
      margin-left: 14px;
      margin-right: 27px;
    }

    .desk__user {
      margin-left: 27px;
    }

    .desk__tableType,
    .desk__limitType {
      height: 18px;
      padding: 0 7px;
      line-height: 18px;
      margin-bottom: 5px;
    }

    .desk__limitType {
      margin-bottom: 7px;
    }

    .desk__banker,
    .desk__player,
    .desk__tie {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 6px;
    }

    .desk__countText {
      margin-right: 6px;

      &:last-child {
        margin-right: 16px;
      }
    }

    .desk__icon {
      width: 20px;
      height: auto;
      margin-right: 6px;
    }
  }
}
