.notice {
  width: 510px;
  color: #fff;

  &__item {
    position: relative;
    max-height: 60px;
    padding: 0 30px;
    overflow: hidden;
    transition: max-height 0.2s;

    &.is_read {
      color: #909399;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      content: "\20";
      height: 1px;
      border-bottom: 1px solid #53504A;
    }

    &.active {
      background: #2B2B2B;
    }

    &.open {
      max-height: 260px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 16px;
    padding-left: 26px;
    cursor: pointer;

    span {
      flex: 1 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__content {
    margin: 0 26px 26px;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    padding: 22px;
    background: #202020;
    border-radius: 6px;
    max-height: 200px;
    overflow-y: auto;
  }
}
