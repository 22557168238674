.list {
  width: 424px;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;

  &__item {
    position: relative;
    display: flex;
    height: 85px;
    align-items: center;
    padding: 0 80px;

    &:after {
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      content: "\20";
      height: 1px;
      border-bottom: 1px solid #53504a;
    }

    &.active {
      background: #2B2B2B;
    }
  }

  &__text {
    flex: 1 1;
  }

  &__icon {
    width: 20px;
    height: 20px;
    background: url("./icon_checked.png") no-repeat center;
    background-size: 100%;
  }
}
